loan-origination-application-summary {
  // override 'not allowed' cursor on disabled fields
  input, select, selectize, textarea {
    cursor: default !important;
  }

  // Remove border from all inputs when printing
  textarea, select, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"] {
    border: none;
    overflow: visible !important;
    background-color: transparent;
    box-shadow: none;
  }

  .file-upload-new__drop-zone {
    visibility: hidden;
    width: 0 !important;
  }

  .hide-buttons button {
    display: none !important;
  }

  table td {
    border: none !important;
  }

  // Hide styling of appended fields (Feet, Inch etc.)
  span.add-on.glue-appended {
    border: none;
    background: transparent;
  }

  // Hide placeholders
  input:not([type="date"])::placeholder, textarea::placeholder {
    color: transparent;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }

  // remove bottom line below customer profile
  customer-profile-body .profile-body .tab-content {
    border: none;
  }

  // remove clearfix after individual/corporate data to wrap documents behind it
  customer-profile-individual-data, customer-profile-corporate-data {
    .clearfix {
      display: none;
    }
  }

  // hide horizontal lines with section title
  .form-header {
    display: none;
  }

  // hide fields displaying min / max principal in loan config
  .row-info-section {
    visibility: hidden;
    width: 0 !important;
  }

  // make selectize displayed like a normal text
  .selectize-input {
    color: var(--text-color);
    opacity: 1 !important;
    border: none;
    box-shadow: none !important;
    background: none !important;
    // hide arrow
    &:after {
      visibility: hidden;
      width: 0;
    }
  }
}