custom-fee-board {
  /*align glue-container's input in center of td*/
}
custom-fee-board .table th,
custom-fee-board .table td {
  text-align: center;
}
custom-fee-board td:nth-child(2) {
  width: unset;
}
custom-fee-board .table input,
custom-fee-board .table select,
custom-fee-board .table cash,
custom-fee-board .table .nx-selectize {
  min-width: 120px;
  width: auto;
}
custom-fee-board .table .nx-selectize {
  min-width: 250px;
}
custom-fee-board .table {
  display: block;
  overflow-x: auto;
  min-height: 180px;
}
custom-fee-board div.input-append {
  justify-content: center;
}
custom-fee-board p.title {
  text-transform: uppercase;
  font-weight: var(--font-weight-semibold);
  border-bottom: 1px solid lightgray;
}
custom-fee-board .form-section {
  align-items: center;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: minmax(min-content, 150px) minmax(300px, min-content);
}
custom-fee-board .form-section > div {
  display: contents;
}
