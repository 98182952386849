loan-origination-assessment .assessment-tables {
  display: flex;
  flex-direction: column;
  background: white;
}
loan-origination-assessment .application-details {
  padding-right: 20px;
}
loan-origination-assessment .application-details tr td:first-child {
  color: var(--text-color-light);
  font-weight: var(--font-weight-semibold);
  width: 160px;
  text-align: right;
}
loan-origination-assessment .application-details td {
  border: none;
}
loan-origination-assessment table.approvals td:first-child,
loan-origination-assessment table.approvals th:first-child {
  width: 10px!important;
}
loan-origination-assessment table.approvals .row-centered {
  text-align: center;
}
loan-origination-assessment table.approvals th {
  border-top: none;
}
