custom-fee-board {

  .table th, .table td {
    text-align: center;
  }

  td:nth-child(2) {
    width: unset;
  }

  .table {
    input,
    select,
    cash,
    .nx-selectize {
      min-width: 120px;
      width: auto;
    }

    .nx-selectize {
      min-width: 250px;
    }
  }

  .table {
    display: block;
    overflow-x: auto;
    min-height: 180px;
  }

  /*align glue-container's input in center of td*/
  div.input-append {
    justify-content: center;
  }

  p.title {
    text-transform: uppercase;
    font-weight: var(--font-weight-semibold);
    border-bottom: 1px solid lightgray;
  }

  .form-section {
    align-items: center;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: minmax(min-content, 150px) minmax(300px, min-content);

    & > div {
      display: contents;
    }
  }

}
