loan-origination-assessment {
  .assessment-tables {
    display: flex;
    flex-direction: column;
    background: white;
  }

  .application-details {
    padding-right: 20px;

    tr td:first-child {
      color: var(--text-color-light);
      font-weight: var(--font-weight-semibold);
      width: 160px;
      text-align: right;
    }

    td {
      border: none;
    }
  }

  table.approvals {
    td, th {
      &:first-child {
        width: 10px!important;
      }
    }

    .row-centered {
      text-align: center;
    }

    th {
      border-top: none;
    }
  }
}