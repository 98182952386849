loan-origination-application-summary input,
loan-origination-application-summary select,
loan-origination-application-summary selectize,
loan-origination-application-summary textarea {
  cursor: default !important;
}
loan-origination-application-summary textarea,
loan-origination-application-summary select,
loan-origination-application-summary input[type="text"],
loan-origination-application-summary input[type="password"],
loan-origination-application-summary input[type="datetime"],
loan-origination-application-summary input[type="datetime-local"],
loan-origination-application-summary input[type="date"],
loan-origination-application-summary input[type="month"],
loan-origination-application-summary input[type="time"],
loan-origination-application-summary input[type="week"],
loan-origination-application-summary input[type="number"],
loan-origination-application-summary input[type="email"],
loan-origination-application-summary input[type="url"],
loan-origination-application-summary input[type="search"],
loan-origination-application-summary input[type="tel"],
loan-origination-application-summary input[type="color"] {
  border: none;
  overflow: visible !important;
  background-color: transparent;
  box-shadow: none;
}
loan-origination-application-summary .file-upload-new__drop-zone {
  visibility: hidden;
  width: 0 !important;
}
loan-origination-application-summary .hide-buttons button {
  display: none !important;
}
loan-origination-application-summary table td {
  border: none !important;
}
loan-origination-application-summary span.add-on.glue-appended {
  border: none;
  background: transparent;
}
loan-origination-application-summary input:not([type="date"])::placeholder,
loan-origination-application-summary textarea::placeholder {
  color: transparent;
}
loan-origination-application-summary select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}
loan-origination-application-summary customer-profile-body .profile-body .tab-content {
  border: none;
}
loan-origination-application-summary customer-profile-individual-data .clearfix,
loan-origination-application-summary customer-profile-corporate-data .clearfix {
  display: none;
}
loan-origination-application-summary .form-header {
  display: none;
}
loan-origination-application-summary .row-info-section {
  visibility: hidden;
  width: 0 !important;
}
loan-origination-application-summary .selectize-input {
  color: var(--text-color);
  opacity: 1 !important;
  border: none;
  box-shadow: none !important;
  background: none !important;
}
loan-origination-application-summary .selectize-input:after {
  visibility: hidden;
  width: 0;
}
