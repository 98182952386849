@import 'mixins';

.sidenav {
  @include box-shadow;
  background: var(--menu-background-color);
  color: var(--text-color-light);
  font-weight: var(--font-weight-semibold);
  padding-top: var(--top-bar-height);
  width: var(--menu-width);

  position: fixed;
  left:  0;
  top:  0;
  bottom:  0;
  z-index: var(--z-index-prioritized);
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  &__tab {
    @include hoverable-row;
    align-items: center;
    background: transparent;
    border: none;
    color: var(--text-color-light);
    display: flex;
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-semibold);
    height: var(--menu-row-height);
    margin-top: 0.6rem;
    padding-left: var(--menu-side-padding);
    padding-right: var(--menu-side-padding);
    text-align: left;
    transition: var(--default-transition);
    width: 100%;

    &--actions {
      position: relative;
    }

    &--selected {
      background: var(--hover-background-color);
      color: var(--primary-color);
    }

    &--selected,
    &:hover {
      @include hovered-row-styles;
      color: var(--primary-color);
    }
  }

  &__version-info {
    align-items: center;
    color: var(--disabled-text-color);
    display: flex;
    margin-bottom: 1rem;
    margin-top: auto;
    padding-left: var(--menu-side-padding);
    padding-right: var(--menu-side-padding);
    padding-top: 9rem;
    height: 3rem;
    user-select: none;
  }

  &-section__header {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: var(--font-size-s);
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
    margin-top: 1.8rem;
    padding-left: var(--menu-side-padding);
    padding-right: 3.2rem;
    position: relative;
    height: var(--menu-row-height);

    &::before {
      background: url(icons/icon-chevron-down.svg) no-repeat left;
      content: "";
      height: 3rem;
      position: absolute;
      right: 1rem;
      top: 0;
      transition: var(--default-transition);
      width: 3rem;
    }

    span {
      flex: 1;
    }

    &--toggled {
      &::before {
        transform: rotate(180deg);
      }
    }

    &.in {
      &::before {
        transform: rotate(180deg);
      }
    }
  }

  i {
    padding-right: var(--menu-side-padding);
  }

  .icon-refresh {
    cursor: pointer;
    margin-left: auto;
  }
}
